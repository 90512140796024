import React from "react";
import "./AnimatedBackground.css";

export default function AnimatedBackground() {
  return (
    <div className="animated-background">
      <div
        className="gradient-layer"
        style={{
          background: `
            radial-gradient(circle at 20% 20%, rgba(29, 162, 216, 0.8) 0%, rgba(14, 66, 104, 0) 60%),
            radial-gradient(circle at 70% 60%, rgba(29, 162, 216, 0.7) 0%, rgba(14, 66, 104, 0) 80%),
            radial-gradient(circle at 90% 90%, rgba(56, 193, 114, 0.65) 0%, rgba(14, 66, 104, 0) 70%)
          `,
          filter: "blur(50px)",
        }}
      />
      <div
        className="gradient-layer"
        style={{
          background: `
            linear-gradient(135deg, 
              rgba(29, 162, 216, 0.6) 0%, 
              rgba(29, 162, 216, 0.55) 25%, 
              rgba(56, 193, 114, 0.5) 50%, 
              rgba(14, 66, 104, 0) 75%
            )
          `,
          clipPath:
            "polygon(0 0, 100% 0, 100% 70%, 75% 85%, 50% 80%, 25% 65%, 0 40%)",
          filter: "blur(40px)",
        }}
      />
      <div
        className="gradient-layer"
        style={{
          background: `
            linear-gradient(90deg, 
              rgba(29, 162, 216, 0.4) 0%, 
              rgba(14, 66, 104, 0) 50%
            )
          `,
          filter: "blur(60px)",
        }}
      />
    </div>
  );
}
